/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { useState, useEffect } from 'react'
import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalize, isNil } from 'lodash'
import { useMutation } from '@apollo/client'
import { usePermissions, useStorage, useClipboard } from 'hooks'
import { PERMANENTLY_DELETE_NODES, CREATE_BLOCK } from 'features/graphql'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice } from 'features/redux'
import { useNodeMenuActions } from 'features/node/hooks'
import { TagsModal } from 'features/tags/components'
import CONFIG from 'helpers/config'
import { menuActions, disableOptions, blockType } from 'helpers/constant'
import { resourceType } from 'helpers/utils'
import { uploadFile } from 'helpers/storage'
import {
  Button,
  ComfirmationModal,
  UpdateModal,
  ShareModalV2,
  ModalExternalButton,
  Toast,
  MoveToMenu,
  UpdateCoverImageOrColorModal,
  AlertWithAction,
} from 'components'
import { EntityType, FollowedNode, Node } from 'types/graphqlSchema'
import { ActionBar, TrashActions } from './components'

interface HeaderActionProps {
  openModal: string
  setOpenModal: (openModal: string) => void
  hideActions?: boolean
  resource?: Node
}

interface CurrentObject {
  type: string
  object: Node
}

const HeaderActions = ({ openModal, setOpenModal, hideActions = false, resource }: HeaderActionProps) => {
  const {
    dispatch,
    user: { followedNodes },
    user,
    setIsUploading,
  } = useAppSlice()
  const { currentProject, getProject, setCurrentProject } = useProjectSlice()
  const { currentBox, boxes, getBox, setBoxes, setCurrentBox } = useBoxSlice()
  const { currentTip, tips, setTips, setCurrentTip } = useTipSlice()
  const [mutateAddBlock] = useMutation(CREATE_BLOCK)

  const { hasPermission } = usePermissions()
  const { onDropUpload, partitionFiles } = useStorage()

  const {
    updateNode,
    deleteNode,
    permanentlyDeleteNode,
    restoreAllNodes,
    updateFollowedNode,
    downloadNode,
    createNewTipNode,
    handleLeaveSharedNode,
  } = useNodeMenuActions()
  const {
    isOpen: isOpenLeaveSharedNode,
    onOpen: onOpenLeaveSharedNode,
    onClose: onCloseLeaveSharedNode,
  } = useDisclosure()
  const { copyToClipBoard } = useClipboard()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [currentNode, setCurrentNode] = useState<Node | undefined>(undefined)
  const [currentObject, setCurrentObject] = useState<CurrentObject | undefined>()
  const [disableMenuOptions, setDisableMenuOptions] = useState<string[]>([])

  const isMobile = useBreakpointValue({
    xs: true,
    sm: true,
    md: true,
    lg: false,
  })
  // Get all the pathname from the location and filter any undefined
  // const pathname = pathname.split('/').filter((x) => x)

  const [mutatePermanentlyDeleteNodes] = useMutation(PERMANENTLY_DELETE_NODES)

  const handleUpdate = async (updatedValue: any) => {
    if (!currentObject) return
    updateNode(updatedValue, currentObject.object)
    setOpenModal('')
  }

  const handleDelete = async () => {
    if (!currentObject) return
    deleteNode(currentObject.object)
    navigate(-1)
    setOpenModal('')
  }

  const handlePermanentlyDelete = () => {
    permanentlyDeleteNode()
    setOpenModal('')
  }

  const handleRestoreAll = () => {
    restoreAllNodes()
    setOpenModal('')
  }

  const handleUndo = async (ids: string[]) => {
    await mutatePermanentlyDeleteNodes({ variables: { nodeIds: ids } })
    // set boxes and tips without fetching again from DB
    const allBoxes = boxes.filter((b: any) => {
      if (!ids.includes(b.id)) {
        return b
      }
    })
    const allTips = tips.filter((t: any) => {
      if (!ids.includes(t.id)) {
        return t
      }
    })
    dispatch(setBoxes(allBoxes))
    dispatch(setTips(allTips))
  }

  const handleShow = (files: File[], createdNodeId: string) => {
    let redirectPath
    const { flatFiles } = partitionFiles(files)
    if (flatFiles.length !== 0) {
      redirectPath = `file/${createdNodeId}`
      navigate(redirectPath)
      return
    }
    dispatch(getBox(createdNodeId || ''))
    if (pathname.includes('personal')) {
      redirectPath = `/personal/${createdNodeId}`
    } else {
      redirectPath = `${createdNodeId}`
    }
    navigate(redirectPath)
  }

  const onUpload = async (acceptedFiles: File[]) => {
    try {
      if (!hasPermission('upload')) return
      dispatch(setIsUploading(true))
      const tipInput = {
        ownerId: user.id,
        projectId: currentProject?.id,
        organizationId: currentProject?.organization?.id,
        parentId: currentBox?.id || currentProject?.id,
        updatedById: user.id,
      }

      const results = await onDropUpload(acceptedFiles, tipInput)
      const newlyCreatedNodeIds = results && results.newlyCreatedNodeIds ? results.newlyCreatedNodeIds : []
      if (newlyCreatedNodeIds.length > 0) {
        if (newlyCreatedNodeIds.length === 1) {
          Toast.show({
            icon: 'check',
            message: `${newlyCreatedNodeIds?.length} ${newlyCreatedNodeIds.length === 1 ? 'item' : 'items'} created.`,
            onUndo: () => handleUndo(newlyCreatedNodeIds),
            onShow: () => handleShow(acceptedFiles, newlyCreatedNodeIds[0]),
          })
        } else {
          Toast.show({
            icon: 'check',
            message: `${newlyCreatedNodeIds?.length} ${newlyCreatedNodeIds.length === 1 ? 'item' : 'items'} created.`,
            onUndo: () => handleUndo(newlyCreatedNodeIds),
          })
        }
      }

      if (results?.error?.length > 0) {
        results.error.forEach((error: any) => {
          console.log(error)
          Toast.show({
            icon: 'error',
            message: error.file.name,
            option: { autoClose: false },
          })
        })
      }
      if (currentBox) {
        dispatch(getBox(currentBox.id))
      } else if (currentProject) {
        dispatch(getProject(currentProject.id))
      }
      dispatch(setIsUploading(false))
    } catch (error) {
      console.log(error)
    }
  }

  const handleUploadNode = async () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('directory', '')
    input.setAttribute('multiple', 'multiple')
    input.setAttribute('webkitdirectory', '')

    // Temporarily add the input to the body to ensure it is in the DOM.
    document.body.appendChild(input)
    input.style.display = 'none' // Hide the input
    input.click()

    input.onchange = () => {
      if (input.files?.length) {
        const myFiles: File[] = []

        // convert file item to File type
        for (let i = 0; i < input.files.length; i += 1) {
          const newFile: any = input.files.item(i)
          newFile.path = `/${newFile.webkitRelativePath || newFile.name}`
          myFiles.push(newFile as File)
        }
        onUpload(myFiles)
      }
      document.body.removeChild(input)
    }
  }

  const handleUploadTip = async () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('multiple', 'multiple')

    // Temporarily add the input to the body to ensure it is in the DOM.
    document.body.appendChild(input)
    input.style.display = 'none' // Hide the input
    input.click()

    input.onchange = () => {
      if (input.files?.length) {
        const myFiles: File[] = []

        // convert file item to File type
        for (let i = 0; i < input.files.length; i += 1) {
          const newFile: any = input.files.item(i)
          newFile.path = `/${newFile.webkitRelativePath || newFile.name}`
          myFiles.push(newFile as File)
        }
        onUpload(myFiles)
      }
      document.body.removeChild(input)
    }
  }

  // Create Tip
  const handleCreateNewTip = async () => {
    const newNode = await createNewTipNode(user, currentProject, currentBox)
    await mutateAddBlock({
      variables: {
        input: {
          nodeId: newNode.id,
          type: blockType.text,
          content: '',
          size: 0,
          extension: '',
          order: 0,
        },
      },
    })
    return navigate(`${pathname}/file/${newNode.id}`, {
      state: {
        contentType: 'tip',
        node: newNode,
      },
    })
  }

  const getCurrentNode = () => {
    if (currentTip) return currentTip
    if (currentBox) return currentBox
    if (currentProject) return currentProject
    return []
  }
  useEffect(() => {
    const node: Node = getCurrentNode()
    if (!node) return
    setCurrentNode(node)
  }, [currentTip, currentBox, currentProject])

  const refreshCurrentNode = () => {
    if (currentTip) dispatch(setCurrentTip({ ...currentTip }))
    if (currentBox) dispatch(setCurrentBox({ ...currentBox }))
    if (currentProject) dispatch(setCurrentProject({ ...currentProject }))
  }

  const handleLinkShare = () => {
    if (!currentNode || !currentNode?.id) return
    copyToClipBoard(`${CONFIG.APP.URL}/link/${currentNode.id}`)
  }

  const isFollowed = () => {
    if (!followedNodes) return false
    if (currentTip) {
      return followedNodes.some((f: FollowedNode) => f.nodeId === currentTip.id)
    }
    if (currentBox) {
      return followedNodes.some((f: FollowedNode) => f.nodeId === currentBox.id)
    }
    if (currentProject) {
      return followedNodes.some((f: FollowedNode) => f.nodeId === currentProject.id)
    }
    return false
  }

  const handleMenuAction = (action: string): void => {
    if (!currentObject) return
    setOpenModal(action)
    switch (action) {
      case menuActions.follow:
        updateFollowedNode(currentObject.object.id)
        break
      case menuActions.download:
        downloadNode(currentObject.object)
        break
      case 'newTip':
        handleCreateNewTip()
        break
      case menuActions.copyLink:
        handleLinkShare()
        break
      case menuActions.uploadBox:
        handleUploadNode()
        break
      case menuActions.uploadTip:
        handleUploadTip()
        break
      case menuActions.unshare:
        onOpenLeaveSharedNode()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!isNil(resource)) {
      setCurrentObject({ type: resource.type.toLowerCase(), object: resource })
    } else if (!isNil(currentTip)) {
      setCurrentObject({ type: 'tip', object: currentTip })
    } else if (!isNil(currentBox)) {
      setCurrentObject({ type: 'box', object: currentBox })
    } else if (!isNil(currentProject)) {
      setCurrentObject({
        type: 'project',
        object: currentProject,
      })
    } else {
      setCurrentObject(undefined)
    }
  }, [resource, currentBox, currentProject, currentTip])

  useEffect(() => {
    if (currentObject) {
      if (pathname.includes('personal') && currentObject.type === 'project') {
        setDisableMenuOptions(disableOptions.personal)
      } else {
        setDisableMenuOptions(disableOptions[currentObject.type] || [])
      }
    }
  }, [currentObject])

  const renderActionArea = () => {
    if (pathname === '/') {
      // home main projects page
      return (
        <Button
          label="Create Project"
          onClick={() => setOpenModal('newProject')}
          variant="defaultButton"
          style={{ flexWrap: 'nowrap', height: '3rem' }}
        />
      )
    }

    if (pathname.includes('trash')) {
      return <TrashActions setOpenModal={setOpenModal} isMobile={isMobile || false} />
    }

    if (currentProject) {
      // inside a project, box, tip, and personal
      return (
        <ActionBar
          setOpenModal={setOpenModal}
          openModal={openModal}
          pathname={pathname}
          currentTip={currentTip}
          currentNode={currentNode}
          follow={isFollowed()}
          disableMenuOptions={disableMenuOptions}
          handleMenuAction={handleMenuAction}
        />
      )
    }
  }

  // render subheader for other pages
  return (
    <>
      {!hideActions && renderActionArea()}

      {/* Update name */}
      <ModalExternalButton header="Rename" isOpen={openModal === menuActions.rename} close={() => setOpenModal('')}>
        <UpdateModal
          placeholder={`Enter a new ${currentObject?.type} name`}
          label="New name"
          startValue={currentObject?.object?.name || ''}
          onCancel={() => setOpenModal('')}
          onUpdate={handleUpdate}
        />
      </ModalExternalButton>

      {currentObject && (
        <ModalExternalButton
          header="Add a cover photo or colour"
          isOpen={openModal === menuActions.coverImage}
          close={() => setOpenModal('')}
        >
          <UpdateCoverImageOrColorModal
            currentCoverImage={currentObject?.object?.coverImageSecureUrl}
            currentFolderColor={currentObject?.object?.color}
            onCancel={() => setOpenModal('')}
            onUpdateColor={(newColor) => {
              // update folder color
              setOpenModal('')
              updateNode({ color: newColor, coverImage: null }, currentObject.object)
            }}
            onUpdateCoverImage={async (newCoverImage) => {
              // update cover image
              setOpenModal('')
              const uploadResponse = await uploadFile(newCoverImage)
              updateNode({ color: null, coverImage: uploadResponse.fileUrl }, currentObject.object)
            }}
          />
        </ModalExternalButton>
      )}

      {/* Delete item */}
      <ComfirmationModal
        name={currentObject?.object?.name || 'Delete'}
        header={`Delete ${currentObject?.type}`}
        confirmAction={handleDelete}
        confirmType="delete"
        isOpen={openModal === menuActions.delete}
        close={() => setOpenModal('')}
      />

      {/* Delete all trash items */}
      <ComfirmationModal
        name="All items"
        header="Permanently delete all"
        confirmAction={handlePermanentlyDelete}
        confirmType="deletePermanently"
        isOpen={openModal === menuActions.permanentlyDelete}
        close={() => setOpenModal('')}
      />

      {/* Restore all trash items */}
      <ComfirmationModal
        name="All items"
        header="Restore All"
        confirmType="restore"
        confirmAction={handleRestoreAll}
        isOpen={openModal === menuActions.restore}
        close={() => setOpenModal('')}
      />

      {/* Move to */}
      <ModalExternalButton
        header={`Move ${currentObject?.object?.name}`}
        modelWidth="580px"
        modelHeight="723px"
        isOpen={openModal === menuActions.moveTo}
        close={() => setOpenModal('')}
      >
        <MoveToMenu closeAction={() => setOpenModal('')} movingResource={currentObject?.object} />
      </ModalExternalButton>

      {currentNode && (
        <>
          <ShareModalV2
            isOpen={openModal === menuActions.share}
            onClose={() => setOpenModal('')}
            node={currentNode}
            setOpenModal={setOpenModal}
          />

          <TagsModal
            nodeType={capitalize(resourceType(currentNode?.type) ?? '')}
            entityName={currentNode?.name}
            entityId={currentNode?.id}
            entityType={EntityType.Node}
            isOpen={openModal === menuActions.tag}
            onClose={(updatedTags) => {
              if (updatedTags) refreshCurrentNode()
              setOpenModal('')
            }}
          />
        </>
      )}

      {/* Remove Myself */}
      {currentObject && currentObject.object.id && (
        <AlertWithAction
          action={() => {
            onCloseLeaveSharedNode()
            handleLeaveSharedNode(currentObject.object.id)
          }}
          isOpen={isOpenLeaveSharedNode}
          onClose={onCloseLeaveSharedNode}
          actionText="Remove Myself"
          warningText={['You will no longer have access to this folder.']}
        />
      )}
    </>
  )
}

export default HeaderActions
