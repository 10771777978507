import { useEffect, useState } from 'react'
import { useAnimate, useReducedMotion } from 'framer-motion'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Divider, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CREATE_BLOCK, GET_NODE } from 'features/graphql'
import { useNodeMenuActions } from 'features/node/hooks'
import { useAppSlice, useProjectSlice } from 'features/redux'
import { useFlowFilters } from 'features/workflow'
import { blockType } from 'helpers/constant'
import { SettingsPopover } from 'components/fragments'
import NavMenuButton from './NavMenuButton'
import NavOrganisationDropdown from './NavOrganisationDropdown'
import { images } from 'theme'
import { isFirefox } from 'react-device-detect'
// import MenuResizableArea from './MenuResizableArea'

interface MenuDesktopProps {
  activeButton: string
  navigateTo: (path: string) => void
}

const MenuDesktop = ({ activeButton, navigateTo }: MenuDesktopProps) => {
  const { user } = useAppSlice()
  const { projects } = useProjectSlice()
  const { createNewTipNode } = useNodeMenuActions()
  const { todoFlows } = useFlowFilters()
  const navigate = useNavigate()
  const [projectsMenuCollapsed, setProjectsMenuCollapsed] = useState<boolean>(false)

  const [mutateAddBlock] = useMutation(CREATE_BLOCK)
  const [fetchPersonalProject] = useLazyQuery(GET_NODE, { variables: { id: user?.myProjectId } })
  const { pathname } = useLocation()
  const isInPersonal = pathname.startsWith('/personal')
  // Flows Indicator
  const [searchParams] = useSearchParams()
  const projectParam = searchParams.get('project')
  // Quickstart
  const [isHoveringQuickstart, setIsHoveringQuickstart] = useState(false)
  const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [scope, animate] = useAnimate()
  const [disableQuickstart, setDisableQuickstart] = useState(false)
  const shouldReduceMotion = useReducedMotion()
  // Create Tip
  const handleQuickstart = async () => {
    setDisableQuickstart(true)
    const {
      data: { node },
    } = await fetchPersonalProject()
    const newNode = await createNewTipNode(user, node, undefined)
    await mutateAddBlock({
      variables: {
        input: {
          nodeId: newNode.id,
          type: blockType.text,
          content: '',
          size: 0,
          extension: '',
          order: 0,
        },
      },
    })
    return navigate(`file/${newNode.id}`, {
      state: {
        contentType: 'tip',
        node: newNode,
        quickstart: true,
      },
    })
  }

  useEffect(() => {
    setTriggerAnimation(isHoveringQuickstart)
  }, [isHoveringQuickstart])

  useEffect(() => {
    if (triggerAnimation) {
      const timeoutId = window.setTimeout(() => {
        setTriggerAnimation(false)
      }, 10)
      return () => {
        window.clearTimeout(timeoutId)
      }
    }
  }, [triggerAnimation])

  useEffect(() => {
    if (triggerAnimation && !shouldReduceMotion) {
      animate('aside', { x: 8 }, { duration: 0.01 })
    } else {
      animate('aside', { x: 0 }, { duration: 0.6 })
    }
  }, [triggerAnimation])

  useEffect(() => {
    if (projects.length === 0 && projectsMenuCollapsed) {
      setProjectsMenuCollapsed(false)
    }
  }, [projects])

  return (
    <Flex
      as="nav"
      justify="flex-start"
      flexDir="column"
      w="236px"
      bg="background"
      h="full"
      borderRight="1px solid var(--chakra-colors-borderRegular)"
    >
      {/* Home Link */}
      <Box ml="1.5rem" mt="2rem" mb=".75rem">
        <Link
          to="/dashboard"
          aria-label="Home"
          style={
            {
              paddingRight: '2rem',
              outline: 'none',
              ':focus-visible': {},
            } as React.CSSProperties
          }
        >
          <Image src={useColorModeValue(images.Logo, images.LogoDark)} alt="Tipbox Logo" />
        </Link>
      </Box>
      {/* Organisation */}
      <NavOrganisationDropdown title={user?.organization?.name || user?.name} />
      <Box px="1.5rem">
        <Divider borderColor="borderRegular" />
      </Box>
      {/* NavMenu */}
      <Flex
        pt="2rem"
        flexDir="column"
        gap="1.5rem"
        overflowY="scroll"
        flex={1}
        css={{
          '&::-webkit-scrollbar': {
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {
            width: '1px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-borderRegular)',
            borderRadius: '6px',
          },
          scrollbarWidth: isFirefox ? 'none' : 'initial',
        }}
      >
        {/* Search */}
        <NavMenuButton
          activeButton={activeButton}
          label="Search"
          path="/search"
          onClick={() => {
            navigateTo('/search')
          }}
          image={images.navSearch}
          highlightImage={images.navSearchHighlight}
        />
        {/* Dashboard */}
        <NavMenuButton
          activeButton={activeButton}
          label="Dashboard"
          path="/dashboard"
          onClick={() => {
            navigateTo('/dashboard')
          }}
          image={images.navDashboard}
          highlightImage={images.navDashboardHighlight}
        />
        {/* Projects */}
        <NavMenuButton
          activeButton={activeButton}
          label="Projects"
          path="/"
          onClick={() => {
            navigateTo('/')
          }}
          image={images.navProjects}
          highlightImage={images.navProjectsHighlight}
        />
        {/* Flows */}
        <NavMenuButton
          activeButton={activeButton}
          label="Flows"
          path="/flows"
          onClick={() => {
            if (projectParam) {
              navigateTo(`/flows?project=${projectParam}`)
            } else {
              navigateTo(`/flows`)
            }
          }}
          image={images.navFlows}
          highlightImage={images.navFlowsHighlight}
          flowsCount={todoFlows.length}
          showFlowsCount={todoFlows.length > 0}
          isFlows
        />
        {/* Personal */}
        <NavMenuButton
          activeButton={activeButton}
          label="Personal"
          path="/personal"
          onClick={() => {
            navigateTo('/personal')
          }}
          image={images.navPersonal}
          highlightImage={images.navPersonalHighlight}
          isInPersonal={isInPersonal}
          isPersonal
        />
        {/* Trash */}
        <NavMenuButton
          activeButton={activeButton}
          label="Trash"
          path="/trash"
          onClick={() => {
            navigateTo('/trash')
          }}
          image={images.navTrash}
          highlightImage={images.navTrashHighlight}
        />
        {/* Quickstart */}
        <Box
          mt="1rem"
          as="button"
          ref={scope}
          onMouseEnter={() => setIsHoveringQuickstart(true)}
          onMouseLeave={() => setIsHoveringQuickstart(false)}
          onFocus={() => setIsHoveringQuickstart(true)}
          onBlur={() => setIsHoveringQuickstart(false)}
          display="flex"
          disabled={disableQuickstart}
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          pl="1.5rem"
          onClick={handleQuickstart}
          style={
            {
              paddingRight: '3px',
              outline: 'none',
              ':focus-visible': {},
            } as React.CSSProperties
          }
        >
          <Text
            fontSize="1rem"
            fontWeight={isHoveringQuickstart ? 500 : 400}
            color={isHoveringQuickstart ? 'textSoft' : 'textRegular'}
          >
            Quickstart
          </Text>
          <Box
            as="aside"
            h="1.5rem"
            w="1.5rem"
            mr="2rem"
            display="grid"
            placeContent="center"
            rounded="full"
            bg={isHoveringQuickstart ? 'accent' : 'transparent'}
            border="2px solid"
            borderColor={isHoveringQuickstart ? 'accent' : 'textRegular'}
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 1V11"
                stroke={isHoveringQuickstart ? 'var(--chakra-colors-textHighlight)' : 'var(--chakra-colors-textSoft)'}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M11 6L1 6"
                stroke={isHoveringQuickstart ? 'var(--chakra-colors-textHighlight)' : 'var(--chakra-colors-textSoft)'}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </Box>
        </Box>
      </Flex>
      <Box w="full" px="1.5rem" mb="5rem">
        <Box pb={7}>
          <Divider borderColor="borderRegular" />
        </Box>
        <SettingsPopover isDesktopNav />
      </Box>
    </Flex>
  )
}

export default MenuDesktop
